import { type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { HTTPError } from 'ky';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { type GsErrors } from 'types/gs_errors';
import { postRequest } from 'utils/rest-api-util';
import FormInput from 'components/atoms/FormInput';
import FormTextArea from 'components/atoms/FormTextArea';

export interface AnalyticData {
  name: string;
  property: string;
  websiteUrl: string;
  credentials: string;
}

const AnalyticForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AnalyticData>({
    defaultValues: {
      name: '',
      property: '',
      websiteUrl: '',
      credentials: '',
    },
  });

  const onSubmit: SubmitHandler<AnalyticData> = async (data) => {
    try {
      setLoading(true);
      await postRequest('api/v1/analytics', data);
      setLoading(false);
      toast('登録しました', { type: 'success' });
      navigate(0);
    } catch (error: unknown) {
      if (error instanceof HTTPError) {
        const response = (await error.response.json()) as GsErrors;
        response.errors.forEach((errorMsg: string) => toast.error(errorMsg));
      }
      setLoading(false);
    }
  };

  return (
    <form className="max-w-xl pl-8" onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="プロパティID"
        attribute="property"
        register={register('property', { required: true })}
        errors={errors}
        required={true}
      />
      <FormInput
        label="サイト名"
        attribute="name"
        register={register('name', { required: true })}
        errors={errors}
        required={true}
      />
      <FormInput
        label="サイトURL"
        attribute="websiteUrl"
        register={register('websiteUrl', { required: true })}
        errors={errors}
        required={true}
      />
      <FormTextArea
        label="credentials"
        attribute="credentials"
        register={register('credentials', { required: true })}
        errors={errors}
        required={true}
        rows={5}
      />
      <Button type={'submit'} color={'gray'} isProcessing={loading}>
        登録する
      </Button>
    </form>
  );
};

export default AnalyticForm;
